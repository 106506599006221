<template>
	<svg
		width="32"
		height="32"
		class="icon icon-magnifier"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M30 29L21.777 20.777"
			stroke="currentColor"
			stroke-width="2"
			stroke-miterlimit="10"
			stroke-linecap="square"
			stroke-linejoin="round"
		/>
		<path
			d="M14 24C20.0751 24 25 19.0751 25 13C25 6.92487 20.0751 2 14 2C7.92487 2 3 6.92487 3 13C3 19.0751 7.92487 24 14 24Z"
			stroke="currentColor"
			stroke-width="2"
			stroke-miterlimit="10"
			stroke-linecap="square"
			stroke-linejoin="round"
		/>
	</svg>
</template>
