import { setAriaProp } from "@/base/utilities";

const collapseEvent = new CustomEvent("collapse", {
	bubbles: true,
});
const expandEvent = new CustomEvent("expand", {
	bubbles: true,
});

export default class AccordionList {
	constructor(config) {
		this.el = config.el;
		this.toggleAllBtn = this.el.querySelector(".accordion-list__toggle-all");
		this.accordions = [];
		this.expandAllOnLoad = false;
		this.toggleAllBtnLabelOpen = this.toggleAllBtn.dataset.openLabel;
		this.toggleAllBtnLabelClose = this.toggleAllBtn.dataset.closeLabel;
		this.init();
	}
	init() {
		this.attachEvents();

		this.accordions = Array.from(
			this.el.querySelectorAll(".accordion-item")
		).map((x) => new AccordionItem({ el: x }));

		this.setToggleAllBtnLabel(); // set initial state of btn label
	}
	attachEvents() {
		this.toggleAllBtn.addEventListener("click", () => {
			if (this.allAreOpen()) {
				this.closeAll();
			} else {
				this.openAll();
			}
		});

		this.el.addEventListener("expand", () => {
			this.setToggleAllBtnLabel();
		});

		this.el.addEventListener("collapse", () => {
			this.setToggleAllBtnLabel();
		});
	}

	setToggleAllBtnLabel() {
		this.toggleAllBtn.textContent = this.allAreOpen()
			? this.toggleAllBtnLabelClose
			: this.toggleAllBtnLabelOpen;
	}
	allAreOpen() {
		return !!this.accordions.length && this.accordions.every((x) => x.expanded);
	}
	openAll() {
		this.setToggleAllBtnLabel();
		this.accordions.forEach((accordion) => {
			accordion.expand();
		});
	}
	closeAll() {
		this.setToggleAllBtnLabel();
		this.accordions.forEach((accordion) => {
			accordion.collapse();
		});
	}
}

export class AccordionItem {
	constructor(config) {
		this.el = config.el;
		this.toggleBtn = this.el.querySelector(".accordion-item__toggle");
		this.contentEl = this.el.querySelector(".accordion-item__content");
		this.expandedClass = "accordion-item--expanded";
		this.expandOnLoad = false;
		this.itemName = this.el.dataset.itemName;
		this.expanded = false;
		this.init();
	}

	init() {
		this.attachEvents();
		const searchParams = new URLSearchParams(window.location.search);

		this.expandOnLoad =
			searchParams.get("accordion-expand-all") === "true" ||
			searchParams.get("accordion-expand") === this.itemName;

		if (this.expandOnLoad) {
			this.expand();
		}
	}

	attachEvents() {
		this.toggleBtn.addEventListener("click", (e) => {
			this.expanded = !this.expanded;

			if (this.expanded) {
				this.expand();
			} else {
				this.collapse();
			}
		});

		this.contentEl.addEventListener("transitionend", (e) => {
			if (e.propertyName === "height") {
				// remove fixed height once accordion animation is done
				this.contentEl.style.height = this.expanded ? "auto" : "";
			}
		});
	}

	expand() {
		this.expanded = true;
		this.el.classList.add(this.expandedClass);
		this.contentEl.removeAttribute("inert");
		setAriaProp(this.contentEl, "hidden", false);
		setAriaProp(this.toggleBtn, "expanded", true);
		this.el.dispatchEvent(expandEvent);

		// animate accordion style
		this.contentEl.style.height = this.contentEl.scrollHeight + "px";
	}

	collapse() {
		this.expanded = false;
		this.el.classList.remove(this.expandedClass);
		this.contentEl.setAttribute("inert", "");
		setAriaProp(this.contentEl, "hidden", true);
		setAriaProp(this.toggleBtn, "expanded", false);
		this.el.dispatchEvent(collapseEvent);

		// animate accordion style
		this.contentEl.style.height = this.contentEl.scrollHeight + "px";
		requestAnimationFrame(() => {
			this.contentEl.style.height = "";
		});
	}
}
