<script setup>
import Magnifier from "./_Magnifier.vue";
import Facet from "./Facet.vue";
import {defineProps, ref, watch} from "vue";

const emit = defineEmits(['query', 'facets']);

const props = defineProps({
  facets: {
    type: Array,
    required: true,
  },
  showFilter: {
    type: Boolean,
    default: true,
  },
  showSearch: {
    type: Boolean,
    default: true,
  },
  resetFilterId: {
    type: String,
    required: true,
  },
  dictionary: {
    type: Object,
    required: true,
  }
})

let query = ref("");
let facets = ref("");

watch(query,(value) => {
  emit("query", value);
})

watch(facets,(value) => {
  emit("facets", value);
})

const onSubmit = (event) => {
  event.preventDefault();

  emit("query", query.value);
  emit("facets", facets.value);
}

const handleUpdateFacetsValue = (value) => {

  if(value === props.resetFilterId) {
    facets.value = "";
    return;
  }
  
  facets.value = value;
}

</script>

<template>
  <form action="" class="mt-2" v-on:submit="onSubmit">
    <div v-if="props.showSearch" class="news-search mb-1">
      <input
          class="input-no-default-style"
          type="search"
          :placeholder="dictionary.searchLabel"
          :aria-label="dictionary.searchLabel"
          v-model="query"
      />
      <button
          :aria-label="dictionary.searchButton"
          :title="dictionary.searchButton"
          type="submit"
      >
        <Magnifier />
      </button>
      <div class="loader" v-if="loading"></div>
    </div>
    <div v-if="props.showFilter" class="grid-noGutter-noBottom">
      <div class="ydelse-facets col_sm-12 mb-2">
        <fieldset>
          <legend class="form-label">{{ dictionary.filterBy }}:</legend>
          <div class="radio-facet-wrapper">
            <Facet
                v-for="(facet) in props.facets"
                :key="facet.MetadataItem.Id"
                :label="facet.MetadataItem.Name"
                :count="facet.Amount"
                :input-value="facet.MetadataItem.Id"
                :facet-value="facets"
                @update:facetValue="handleUpdateFacetsValue"
            />
            <button
                class="btn btn--link ydelse-facets__toggle-more"
                type="button"
                v-if="renderShowMoreButton"
                @click="showingMore = !showingMore"
            >
              <span v-if="!showingMore">{{ dictionary.viewAll }}</span>
              <span v-else>{{ dictionary.viewLess }}</span>
              <span class="sr-only">{{ dictionary.filteringOptions }}</span>
            </button>
          </div>
        </fieldset>
      </div>
    </div>
  </form>
</template>
