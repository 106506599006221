import { prefersReducedMotion } from "@/base/utilities";
export default class ScrollToTop {
	constructor(config) {
		this.el = config.el;

		this.init();
	}
	init() {
		this.attachEvents();
	}
	attachEvents() {
		const focusableElements =
			'button, a[href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

		this.el.addEventListener("click", () => {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: prefersReducedMotion() ? "auto" : "smooth",
			});
			document.querySelector(focusableElements).focus();
		});
	}
}
