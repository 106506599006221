import "./main.scss";
import HeaderSmall from "./js/components/header-small.js";
import HeaderSearch from "./js/components/header-search.js";
import ScrollToTop from "./js/components/scroll-to-top.js";
import SignLanguageHelper from "./js/components/sign-language-helper";
import SubscriptionModule from "./js/components/subscription-module";
import ImageGallery from "./js/components/image-gallery";
import LeftMenu from "./js/components/left-menu";
import AccordionList from "./js/components/accordion-list";
import ShareFunctions from "./js/components/share-functions.js";
import "lazysizes";
import "lazysizes/plugins/print/ls.print";
import YdelseList from "./js/components/ydelses-list/YdelseList.vue";
import { initComponent, initVueApp } from "./js/base/utilities";
import { VueQueryPlugin } from "@tanstack/vue-query";
import PrintToPdf from "./js/components/print-to-pdf";

document.addEventListener("DOMContentLoaded", function () {
	initComponent(HeaderSmall, {
		el: document.querySelectorAll(".header-small"),
	});
	initComponent(HeaderSearch, {
		el: document.querySelectorAll(".header-search"),
	});
	initComponent(SignLanguageHelper, {
		el: document.querySelectorAll(".js-sign-language-toggle"),
	});
	initComponent(ScrollToTop, {
		el: document.querySelectorAll(".js-scroll-button"),
	});
	initComponent(SubscriptionModule, {
		el: document.querySelectorAll(".js-subscription-module"),
	});
	initComponent(ImageGallery, {
		el: document.querySelectorAll(".js-image-gallery"),
	});
	initComponent(LeftMenu, {
		el: document.querySelectorAll(".left-menu"),
	});
	initComponent(AccordionList, {
		el: document.querySelectorAll(".js-accordion-list"),
	});
	initComponent(PrintToPdf, {
		el: document.querySelectorAll(".js-print-to-pdf"),
	});
	initComponent(ShareFunctions, {
		el: document.querySelectorAll(".js-share-functions"),
	});
	initVueApp(
		YdelseList,
		{
			el: document.querySelectorAll(".js-ydelse-list"),
		},
		(createApp, element, vueData, props) =>
			createApp(vueData, props.model).use(VueQueryPlugin).mount(element)
	);
});
