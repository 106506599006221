/**
 * Class: utilities
 * Description: Utilities functions
 */
import Emitter from "./emitter";
import { createApp } from "vue"; // this is mapped in webpack configuration in order to load the same version of vue from within components

export const emitter = new Emitter();

export const html = document.querySelectorAll("html");
export const body = document.querySelectorAll("body");
export const main = document.querySelectorAll("#main");

export let modules = [];

// short an sweet polyfill for ie11 forEach
if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = Array.prototype.forEach;
}
if (window.HTMLCollection && !HTMLCollection.prototype.forEach) {
	HTMLCollection.prototype.forEach = Array.prototype.forEach;
}

export function initComponent(moduleClass, config) {
	// init new modules and
	if (config && config.el) {
		if (typeof config.el === "string") {
			config.el = document.querySelectorAll(config.el);
		}
		if (typeof config.el == "object") {
			config.el.forEach(function (el, index) {
				// let localConfig = { ...config, ...{ el: el } };
				let localConfig = Object.assign({}, config, { el: el });
				if (localConfig.name) {
					if (index > 0) {
						console.warn(
							"Module init: more than one module with the selector exists in the dom, be aware that there will only be on in the modulelist"
						);
					}
					modules[localConfig.name] = new moduleClass(localConfig);
				} else {
					modules.push(new moduleClass(localConfig));
				}
			});
		}
	} else {
		console.warn(
			"Module init: Please check that the provided selector and module-class exists"
		);
	}
}

export function initVueApp(vueComponent, config, mountFn) {
	// init new modules
	if ((typeof config.el === "string" || "object") && vueComponent) {
		let elements;

		if (typeof config.el == "string") {
			elements = document.querySelectorAll(config.el);
		} else if (typeof config.el == "object") {
			elements = config.el;
		}
		elements.forEach((element) => {
			let vueData = vueComponent;

			// pass props to the root component by using data-attributes
			const props = Object.assign({}, element.dataset);
			Object.keys(props).forEach((key) => {
				try {
					props[key] = JSON.parse(props[key]);
				} catch (error) {
					// prop isn't valid json, so do nothing and just return the dataset string
				}
			});

			var vueApp = mountFn ?  mountFn(createApp, element, vueData, props) : createApp(vueData, props).use(VueQueryPlugin).mount(element);
		});
	} else {
		console.warn(
			"Problem with init VueApp: Please check that the provided selector and vue-component exists",
			vueComponent,
			config
		);
	}
}

export function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this,
			args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) {
				func.apply(context, args);
			}
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait || 200);
		if (callNow) {
			func.apply(context, args);
		}
	};
}

// get size of window.
export function getSize() {
	return window
		.getComputedStyle(document.querySelector("body"), ":before")
		.getPropertyValue("content")
		.replace(/\"/g, "");
}

var lastSize = getSize();
window.addEventListener(
	"resize",
	debounce(function () {
		const size = getSize();
		emitter.emit("responsive/resize", size);
		if (size !== lastSize) {
			emitter.emit("responsive/sizechange", size);
			lastSize = size;
		}
	}, 50)
);
var prevScrollpos = window.pageYOffset;
window.addEventListener(
	"scroll",
	debounce(function () {
		var currentScrollPos = window.pageYOffset;
		var direction = "up";
		if (prevScrollpos - 5 > currentScrollPos) {
			// add some buffer so we dont get flickr
			direction = "up";
		} else if (prevScrollpos + 20 < currentScrollPos) {
			// add som buffer so the user can scroll slowly down the page without this triggering
			direction = "down";
		} else {
			direction = "";
		}
		prevScrollpos = currentScrollPos;
		emitter.emit("window/scroll", {
			top: window.pageYOffset,
			direction: direction,
		});
	}, 10)
);
export function setHashUrl(hash) {
	if (history.replaceState) {
		history.replaceState(null, null, "#" + hash);
	} else {
		location.hash = "#" + hash;
	}
}
export function setQueryStringParameter(name, value) {
	const params = new URLSearchParams(window.location.search);
	params.set(name, value);
	window.history.replaceState(
		{},
		"",
		decodeURIComponent(`${window.location.pathname}?${params}`)
	);
}
export function deleteQueryStringParameter(name) {
	const params = new URLSearchParams(window.location.search);
	params.delete(name);
	window.history.replaceState(
		{},
		"",
		decodeURIComponent(`${window.location.pathname}?${params}`)
	);
}
export function resetHashUrl() {
	if (history.replaceState) {
		history.replaceState(null, null, "./");
	} else {
		location.hash = "";
	}
}

export function setAriaProp($el, prop, value) {
	$el.setAttribute("aria-" + prop, value);
}

export function createCookie(name, value, days) {
	var expires = "";
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = "; expires=" + date.toUTCString();
	}
	document.cookie = name + "=" + value + expires + "; path=/";
}

export function readCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(";");
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == " ") c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
}

export function eraseCookie(name) {
	createCookie(name, "", -1);
}

export function focusWhitin() {
	var slice = [].slice;
	var removeClass = function (elem) {
		elem.classList.remove("js-focus-within");
	};
	var update = (function () {
		var running, last;
		var action = function () {
			var element = document.activeElement;
			running = false;
			if (last !== element) {
				last = element;
				slice
					.call(document.getElementsByClassName("js-focus-within"))
					.forEach(removeClass);
				while (element && element.classList) {
					element.classList.add("js-focus-within");
					element = element.parentNode;
				}
			}
		};
		return function () {
			if (!running) {
				requestAnimationFrame(action);
				running = true;
			}
		};
	})();
	document.addEventListener("focus", update, true);
	document.addEventListener("blur", update, true);
	update();
}

function testObjectfit() {
	if (
		"objectFit" in document.documentElement.style !== false &&
		!window.navigator.userAgent.indexOf("Edge/16.") !== -1
	) {
		return true;
	} else {
		return false;
	}
}

export function objectFitToParentBackground(el) {
	const source = el.getAttribute("src");
	el.parentElement.style.backgroundImage = "url(" + source + ")";
	el.parentElement.style.backgroundSize = "cover";
	el.parentElement.style.backgroundPosition = "center center";
	el.style.opacity = 0;
}
export const supportsObjectfit = testObjectfit("object-fit");

export function formatDate(date) {
	const tempDate = new Date(date);
	const dateString =
		("0" + tempDate.getDate()).slice(-2) +
		"." +
		("0" + (tempDate.getMonth() + 1)).slice(-2) +
		"." +
		tempDate.getFullYear();
	return dateString;
}

export function prefersReducedMotion() {
	const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");

	return mediaQuery && mediaQuery.matches;
}

export function slugify(text) {
	return text
		.toString()
		.toLowerCase()
		.replace(/\s+/g, "-")
		.replace(/[^\w-]+/g, "")
		.replace(/--+/g, "-")
		.replace(/^-+/, "")
		.replace(/-+$/, "");
}

export function isMatchingIds(id1, id2) {
	return (
		id1.replaceAll(/{|}/g, "").toLowerCase() ===
		id2.replaceAll(/{|}/g, "").toLowerCase()
	);
}
