import { setAriaProp } from "@/base/utilities";
export default class SubscriptionModule {
	constructor(config) {
		this.el = config.el || document.querySelectorAll(".js-subscription-module");
		this.moduleInputField = this.el.querySelector(".js-subscription-input");
		this.moduleBtn = this.el.querySelector(".js-subscription-btn");
		this.moduleMessage = this.el.querySelector(".subscription-module__message");
		this.moduleForm = this.el.querySelector(".subscription-module__form");
		this.messageActiveClass = "subscription-module__message--active";
		this.moduleInner = this.el.querySelector(".subscription-module__inner");
		this.submittedClass = "submitted";
		this.init();
	}
	init() {
		this.attachEvents();
	}
	attachEvents() {
		const self = this;
		this.moduleForm.addEventListener("submit", function (e) {
			e.preventDefault();
			self.submit();
		});
	}
	submit() {
		const self = this;
		let pageId = this.moduleInner.dataset.pageid;
		if (/^\S+@\S+$/i.test(this.moduleInputField.value)) {
			self.moduleMessage.classList.add(self.messageActiveClass);
			self.moduleForm.classList.add(self.submittedClass);
			self.setVisibility(self.moduleForm, true, -1);
			self.setVisibility(self.moduleMessage, false, 0);
			self.setVisibility(self.moduleInputField, true, -1);
			self.setVisibility(self.moduleBtn, true, -1);
			self.moduleMessage.focus();

			fetch(`/api/subscription/Subscribe?emailInput=${self.moduleInputField.value}&pageId=${pageId}`)
			.then((response) => {
				return response.json();
			})
			.catch((e) => console.error(e));
		} else {
			self.moduleMessage.classList.remove(self.messageActiveClass);
			self.moduleForm.classList.remove(self.submittedClass);
			self.setVisibility(self.moduleMessage, true, -1);
			self.setVisibility(self.moduleForm, false, 0);
			self.setVisibility(self.moduleInputField, false, 0);
			self.setVisibility(self.moduleBtn, false, 0);
		}
	}
	setVisibility($item, status, tabindex) {
		setAriaProp($item, "hidden", status);
		$item.setAttribute("tabindex", tabindex);
	}
}
