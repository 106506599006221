<script setup>
import {computed, defineProps} from "vue";

const emit = defineEmits(["prev", "next", "setPage"]);

const props = defineProps({
  currentPage: {
    type: Number,
    required: true,
  },
  maxPages: {
    type: Number,
    required: true,
  },
  dictionary: {
    type: Object,
    required: true,
  },
})

const isPrevDisabled = computed(() => props.currentPage === 1);
const isNextDisabled = computed(() => props.currentPage === props.maxPages);

const isActive = (value) => props.currentPage === value;
</script>

<template>
	<nav class="pagination mb-1 mt-1" aria-label="Paginering navigation">
		<ol class="d-sm-none d-flex">
			<li>
				<Button
					:aria-label="dictionary.goToPreviousPage"
					:aria-disabled="isPrevDisabled"
					tabindex="-1"
					class="btn btn--cta btn--outline btn--rounded prev"
          :class="{disabled: isPrevDisabled}"
          @click="emit('prev')"
				>
					{{ dictionary.previous }}
				</Button>
			</li>
			<li v-if="props.maxPages <= 5" v-for="index in props.maxPages" :key="index">
				<Button
					:aria-label="isActive(index) 
					  ? `${dictionary.activePage}. ${dictionary.page} ${index}` 
					  : `Gå til side ${index}`"
					:aria-current="isActive(index) ? 'page' : null"
					class="btn btn--cta btn--rounded"
          :class="{active: isActive(index), 'btn--outline': !isActive(index)}"
          @click="emit('setPage', index)"
				>
					{{ index }}
				</Button>
			</li>
      <li v-else>
        <select :aria-label="dictionary.choosePage" :value="currentPage" @change="(event) => emit('setPage', parseInt(event.target.value))">
          <option v-for="index in props.maxPages" :value="index" :aria-current="isActive(index) ? 'page' : null">{{ `${index}/${props.maxPages}` }}</option>
        </select>
      </li>
			<li>
				<Button
					:aria-label="dictionary.goToNextPage"
          :aria-disabled="isNextDisabled"
					class="btn btn--cta btn--outline btn--rounded next"
          :class="{disabled: isNextDisabled}"
          @click="emit('next')"
					>{{ dictionary.next }}</Button
				>
			</li>
		</ol>
		<ol class="d-sm-flex d-none">
			<li>
				<Button
					:aria-label="dictionary.goToPreviousPage"
					:aria-disabled="isPrevDisabled"
					class="btn btn--cta btn--rounded prev"
          :class="{disabled: isPrevDisabled}"
          @click="emit('prev')"
				>
					{{ dictionary.previous }}
				</Button>
			</li>
			<li>
				<select :aria-label="dictionary.choosePage" :value="currentPage" @change="(event) => emit('setPage', parseInt(event.target.value))">
					<option v-for="index in props.maxPages" :value="index" :aria-current="isActive(index) ? 'page' : null">{{ `${index}/${props.maxPages}` }}</option>
				</select>
			</li>
			<li>
				<Button
					:aria-label="dictionary.goToNextPage"
          :aria-disabled="isNextDisabled"
					class="btn btn--cta btn--rounded next"
          :class="{disabled: isNextDisabled}"
          @click="emit('next')"
					>{{ dictionary.next }}</Button
				>
			</li>
		</ol>
	</nav>
</template>
