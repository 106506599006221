import { readCookie } from "@/base/utilities";
export default class SignLanguageHelper {
	constructor(config) {
		this.el = config.el;
		this.isActive = readCookie("SignIsActive") === "true";

		this.init();
	}
	init() {
		if (this.isActive) {
			this.el.checked = true;
		}
	}
}
