﻿import { prefersReducedMotion } from "@/base/utilities";
export default class PrintToPdf {
    constructor(config) {
        this.el = config.el;

        this.init();
    }
    init() {
        this.setPrintLink()
        this.printLink();
    }
    printLink() {
        const printLink = document.querySelector(".print-button-image-ydelse");
        
        if (!printLink.href) return;
            
        window.addEventListener("keydown", function (event) {
            if (event.ctrlKey && event.key === "p")
                {
                    event.preventDefault();
                    window.open(printLink.href);
                }
        });
    }

    setPrintLink() {
        var hiddenInput = document.getElementById("pdfUrlHiddenInput");
        var pdfUrl = hiddenInput.value;
        var pdfLink = document.getElementById("pdfLink");
        var linkParent = pdfLink.parentElement;
        
        if (pdfUrl)
        {
            pdfLink.href = pdfUrl;
        } else {
            linkParent.style.display = "none";
        }
    }
}
