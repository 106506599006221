<script setup>
import {defineProps, defineEmits, computed} from "vue";

const props = defineProps({
  label: {
    type: String,
  },
  count: {
    type: Number,
    default: 0,
  },
  facetValue: {
    type: String,
    required: true,
  },
  inputValue: {
    type: String,
    required: true,
  }
});

const emit = defineEmits(["update:facetValue"]);

const value = computed({
  get() {
    return props.facetValue
  },
  set(value) {
    emit('update:facetValue', value)
  }
})

</script>

<template>
	<div class="radio-facet">
		<input
			class="input-no-default-style"
			type="radio"
			name="ydelseType"
      :checked="value === inputValue"
			:id="inputValue"
			:value="inputValue"
			v-model="value"
			novalidate
		/>
		<label :for="inputValue">{{ label }} ({{ count }})</label>
	</div>
</template>
