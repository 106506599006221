import Swiper, { Pagination, Scrollbar, Navigation } from "swiper";

// import Swiper and modules styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// configure Swiper to use modules
Swiper.use([Pagination, Scrollbar, Navigation]);

export default class ImageGallery {
	constructor(config) {
		this.el = config.el || document.querySelectorAll(".js-image-gallery");
		this.swiper = this.el.querySelector(".image-gallery__swiper");
		this.currentElemnt = null;
		this.useAdaptiveHeight = this.el.dataset.adaptiveHeight === "true";
		this.init();
	}

	init() {
		this.setSwiper();
	}

	setSwiper() {
		new Swiper(this.swiper, {
			slidesPerView: "auto",
			effect: "fade",
			centeredSlides: false,
			slidesPerGroupSkip: 1,
			grabCursor: true,
			// loop: true,
			autoHeight: this.useAdaptiveHeight,
			pagination: {
				el: ".swiper-pagination",
				type: "fraction",
			},
			scrollbar: {
				el: ".swiper-scrollbar",
				draggable: true,
				hide: false,
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
		});
	}
}
