import { setAriaProp } from "@/base/utilities";
export default class HeaderSearch {
	constructor(config) {
		this.el = config.el || document.querySelectorAll(".header-search");

		this.searchToggleBtn = this.el.querySelector(".header-search__search-btn");
		this.closeLabel = this.searchToggleBtn.dataset.labelClose;
		this.openLabel = this.searchToggleBtn.dataset.labelOpen;
		this.searchFormWrapper = this.el.querySelector(
			".header-search__cludo-search-form-wrapper"
		);
		this.searchInput = this.el.querySelector(".search-form__input");
		this.open = false;

		this.init();
	}
	init() {
		this.attachEvents();
	}
	attachEvents() {
		this.searchToggleBtn.addEventListener("click", () => {
			this.togglePanel();
		});
	}
	togglePanel() {
		if (this.open) {
			this.hidePanel();
		} else {
			this.showPanel();
		}
	}
	showPanel() {
		this.open = true;
		this.el.classList.add("header-search--open");
		setAriaProp(this.searchFormWrapper, "hidden", false);
		this.searchInput.focus();
		this.searchToggleBtn.setAttribute("title", this.closeLabel);
	}
	hidePanel() {
		this.open = false;
		this.el.classList.remove("header-search--open");
		setAriaProp(this.searchFormWrapper, "hidden", true);
		this.searchToggleBtn.setAttribute("title", this.openLabel);
	}
}
