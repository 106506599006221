export default class LeftMenu {
	constructor(config) {
		this.el = config.el || document.querySelectorAll(".left-menu");
		this.init();
	}
	init() {
		this.attachEvents();
	}
	attachEvents() {
		let expandables = this.el.querySelectorAll(
			".left-menu__navigation-item--expandable"
		);
		const self = this;
		expandables.forEach((menuItem) => {
			const button = menuItem.querySelectorAll(":scope > div button")[0];
			self.buttonEvent(button, menuItem);
		});
	}
	loadMenu(id, menuItemClicked, button) {
		let templateItem = document.querySelector("#leftMenuItem");
		let ulContainer = document.createElement("ul");

		fetch(`/api/navigationapi/getmenu?pageItemId=${id}`)
			.then((response) => {
				return response.json();
			})
			.then(({ MenuItems }) => {
				if (MenuItems != null && MenuItems.length > 0) {
					MenuItems.forEach((menuItem) => {
						let cloneItem = templateItem.content.cloneNode(true);

						let linkItem = cloneItem.querySelector("[data-id='linkItem']");
						linkItem.textContent = `${menuItem.MenuTitle}`;
						linkItem.setAttribute("href", menuItem.Url);
						if (menuItem.HasChildren) {
							let liItem = cloneItem.querySelectorAll(":scope li")[0];
							liItem.classList.add("left-menu__navigation-item--expandable");
							liItem.setAttribute("data-root-id", menuItem.Id);
							let button = cloneItem.querySelectorAll("button")[0];
							this.buttonEvent(button, liItem);
						}
						ulContainer.appendChild(cloneItem);
					});
					menuItemClicked.appendChild(ulContainer);
					setTimeout(() => {
						this.openMenu(menuItemClicked, button);
					}, 0);
				}
			})
			.catch((e) => console.error(e));
	}
	closeMenu(menuItem, button) {
		menuItem.classList.remove("left-menu__navigation-item--expanded");
		button.setAttribute("aria-label", "Udvid undermenu");
	}
	openMenu(menuItem, button) {
		menuItem.classList.add("left-menu__navigation-item--expanded");
		button.setAttribute("aria-label", "Skjul undermenu");
	}
	buttonEvent(button, menuItem) {
		const self = this;
		const id = menuItem.dataset.rootId;
		button.addEventListener("click", () => {
			if (
				!menuItem.classList.contains("left-menu__navigation-item--expanded")
			) {
				if (menuItem.querySelectorAll("ul").length === 0) {
					self.loadMenu(id, menuItem, button);
				} else {
					self.openMenu(menuItem, button);
				}
			} else {
				self.closeMenu(menuItem, button);
			}
		});
	}
}
